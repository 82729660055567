import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteSkill, editSkills } from '../../store/actions/actionCreator';
import Swal from 'sweetalert2';
export default function EditSkillModal(props) {
    const { selected } = props;
    const dispatch = useDispatch();
    const inputHandler = (e) => {
        const { value, name } = e.target;
        const newForm = {
            ...form,
        };
        newForm[name] = value;
        setForm(newForm);
    };
    const emptyForm = {
        name: '',
        id: '',
        JobId: '',
        companyName: '',
        level: '',
    };
    const [form, setForm] = useState(emptyForm);
    const [errMsg, setErrMsg] = useState(null);
    useEffect(() => {
        if (selected) {
            setForm(selected);
        } else {
            setForm(emptyForm);
        }
        setErrMsg(null);
    }, [selected]);
    const editHandler = async (e) => {
        if (form.name === '') return setErrMsg('Nama tidak boleh kosong');
        if (form.level === '') return setErrMsg('Level tidak boleh kosong');
        dispatch(editSkills(form));
        document.getElementById('edit-skill-modal').checked = false;
    };
    const deleteHandler = async (e) => {
        Swal.fire({
            title: 'Konfirmasi',
            text: `Hapus ${form.name}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteSkill(form.id))
                    .then((_) => {
                        document.getElementById('edit-skill-modal').checked = false;
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: `Skill ${form.name} berhasil dihapus`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.message,
                        });
                    });
            }
        });
    };

    return (
        <>
            <input type='checkbox' id='edit-skill-modal' className='modal-toggle' />
            <div className='modal'>
                <div className='modal-box relative'>
                    <label htmlFor='edit-skill-modal' className='btn btn-sm btn-circle absolute right-2 top-2'>
                        x
                    </label>
                    <div className='px-6 py-6 lg:px-8'>
                        <h3 className='mb-4 text-xl font-medium text-gray-900 text-center'>Edit skill untuk {form.companyName}</h3>
                        {errMsg && (
                            <div className='flex w-full text-red-700 justify-center bg-red-100 py-1 rounded-full'>
                                <span>{errMsg}</span>
                            </div>
                        )}
                        <div>
                            <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900'>
                                Nama Skill
                            </label>
                            <input
                                value={form.name}
                                name='name'
                                onChange={inputHandler}
                                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                            />
                        </div>
                        <div className='mt-5'>
                            <label htmlFor='password' className='block mb-2 text-sm font-medium text-gray-900'>
                                Level
                            </label>
                            <select
                                value={form.level}
                                name='level'
                                onChange={inputHandler}
                                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                                <option value='' selected disabled>
                                    Pilih Jenis Loker
                                </option>
                                <option value='beginner'>beginner</option>
                                <option value='intermediate'>intermediate</option>
                                <option value='advanced'>advanced</option>
                            </select>
                        </div>
                        <div className='flex gap-2 mt-5'>
                            <button
                                onClick={editHandler}
                                className='w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'>
                                Edit
                            </button>
                            <button
                                onClick={deleteHandler}
                                className='w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'>
                                Hapus
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
