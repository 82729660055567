import { JOBS_FETCH_SUCCESS, JOB_DETAIL_FETCH_SUCCESS } from '../actions/actionType';

const initialState = {
    value: [],
    detailJob: {},
};

function jobReducer(state = initialState, action) {
    switch (action.type) {
        case JOBS_FETCH_SUCCESS:
            return {
                ...state,
                value: action.payload,
            };
        case JOB_DETAIL_FETCH_SUCCESS:
            return {
                ...state,
                detail: action.payload,
            };
        default:
            return state;
    }
}

export default jobReducer;
