function Button(props) {
    const { className = `bg-slate-900 hover:bg-slate-600 text-white`, text, children } = props;
    return (
        <button {...props} className={`${className} flex justify-center gap-x-2 items-center py-2 px-4 rounded-md cursor-pointer `}>
            {text || children}
        </button>
    );
}

export default Button;
