import { useDispatch } from 'react-redux';
import { deleteJob } from '../../store/actions/actionCreator';
import Swal from 'sweetalert2';
export default function JobsRow(props) {
    const dispatch = useDispatch();
    const { index, value, setSelected, setFormType, setSelectedSkill } = props;
    const { id, title, jobType, description, Company, User, Skills } = value;
    function handleButtonDelete() {
        Swal.fire({
            title: 'Konfirmasi',
            text: `Hapus ${title}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteJob(id))
                    .then((_) => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: `Loker ${title} berhasil dihapus`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.message,
                        });
                    });
            }
        });
    }

    return (
        <tr className='hover:font-semibold font-thin text-gray-800 hover:bg-blue-50 text-center border-2 border-gray-200'>
            <th className='py-5 px-3'>{index + 1}</th>
            <td className='py-5 px-4 text-[17px] whitespace-pre-wrap w-[15%]'>{title}</td>
            <td className='py-5 px-4'>{jobType}</td>
            <td className='py-5 px-4 w-[500px] h-[20px] text-[12px] overflow-hidden text-ellipsis'>{description}</td>
            <td className='py-5 px-4'>{Company.name}</td>
            <td className='py-5 px-10 flex gap-2 justify-center items-center flex-wrap mx-auto w-[350px]'>
                {Skills.map((skill) => {
                    let color = '';
                    switch (skill.level) {
                        case 'beginner':
                            color = `bg-green-100 text-green-800 hover:bg-green-300 `;
                            break;
                        case 'intermediate':
                            color = `bg-yellow-100 text-yellow-800 hover:bg-yellow-500 `;
                            break;
                        case 'advanced':
                            color = `bg-red-100 text-red-800 hover:bg-red-300 `;
                            break;
                    }
                    return (
                        <label
                            onClick={() => {
                                setSelectedSkill({
                                    name: skill.name,
                                    id: skill.id,
                                    JobId: id,
                                    companyName: Company.name,
                                    level: skill.level,
                                });
                            }}
                            htmlFor='edit-skill-modal'
                            className={color + ' cursor-pointer text-md b font-medium border-2 border-white mr-2 px-2.5 py-1 rounded-full'}>
                            {skill.name}
                        </label>
                    );
                })}
                <label
                    onClick={() => {
                        setSelected(value);
                    }}
                    htmlFor='skill-modal'
                    className='py-1 px-2  bg-gray-300 hover:bg-gray-500 text-gray-800 font-semibold cursor-pointer text-center rounded-xl'>
                    +
                </label>
            </td>
            <td className='py-5 px-4'>{User.username}</td>
            <td className='py-5 px-4 flex flex-col gap-2'>
                <label
                    onClick={() => {
                        setSelected(value);
                        setFormType('edit');
                    }}
                    htmlFor='jobs-modal'
                    className='py-1 px-1 bg-blue-600 hover:bg-blue-500 text-white w-[100px] font-semibold cursor-pointer text-center rounded-lg'>
                    Edit
                </label>
                <button
                    onClick={handleButtonDelete}
                    className='py-1 px-1 bg-red-600 hover:bg-red-500 text-white w-[100px] font-semibold cursor-pointer text-center rounded-lg'>
                    Delete
                </button>
            </td>
        </tr>
    );
}
