import { useState } from 'react';
import JobsRow from '../atoms/JobsRow';
import FormJobModal from '../atoms/FormJobModal';
import AddSkillModal from '../atoms/addSkillModal';
import EditSkillModal from '../atoms/EditSkillModal';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchJobs } from '../../store/actions/actionCreator';

export default function TableJobs() {
    const jobs = useSelector((state) => {
        return state.job.value;
    });
    const [selected, setSelected] = useState(null);
    const [formType, setFormType] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchJobs());
    }, [dispatch]);

    return (
        <div className='overflow-x-auto h-screen w-full flex flex-col px-10 py-5'>
            <div className='text-3xl my-2 text-center font-bold'>Jobs List</div>
            <FormJobModal selected={selected} formType={formType} />
            <AddSkillModal selected={selected} />
            <EditSkillModal selected={selectedSkill} />
            <label
                onClick={() => {
                    setSelected(null);
                    setFormType('tambah');
                }}
                htmlFor='jobs-modal'
                className='py-2 my-2 bg-blue-600 hover:bg-blue-500 text-white w-[150px] font-semibold cursor-pointer text-center rounded-lg'>
                Tambah Loker
            </label>
            <table className='mx-auto border-2 border-gray-600'>
                <thead className='bg-gray-700 text-white'>
                    <tr className='border-2 border-gray-600'>
                        <th>#</th>
                        <th>Nama Loker</th>
                        <th>Jenis</th>
                        <th>Deskripsi Kerja</th>
                        <th>Nama Perusahaan</th>
                        <th>Requirement Skill</th>
                        <th>Diposting</th>
                        <th>Aksi</th>
                    </tr>
                </thead>
                <tbody className='bg-white'>
                    {jobs.map((job, i) => {
                        return (
                            <JobsRow
                                key={job.id}
                                setSelected={setSelected}
                                setSelectedSkill={setSelectedSkill}
                                setFormType={setFormType}
                                value={job}
                                index={i}
                            />
                        );
                    })}
                </tbody>
            </table>
            <div className='py-10'></div>
        </div>
    );
}
