import { createBrowserRouter, redirect } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import TableJobs from '../components/molecules/TableJobs';
import TableCompanies from '../components/molecules/TableCompanies';
import Dashboard from '../components/molecules/Dashboard';
import User from '../components/molecules/User';

const router = createBrowserRouter([
    {
        element: <HomePage />,
        loader: async () => {
            if (!localStorage.access_token) {
                return redirect('/login');
            }
            return null;
        },
        children: [
            {
                path: '/',
                element: <Dashboard />,
            },
            {
                path: 'jobs',
                element: <TableJobs />,
            },
            {
                path: 'companies',
                element: <TableCompanies />,
            },
            {
                path: 'users',
                element: <User />,
            },
        ],
    },
    {
        path: '/login',
        loader: async () => {
            if (localStorage.access_token) {
                return redirect('/');
            }
            return null;
        },
        element: <LoginPage />,
    },
    {
        path: '/register',
        loader: async () => {
            if (localStorage.access_token) {
                return redirect('/');
            }
            return null;
        },
        element: <RegisterPage />,
    },
]);

export default router;
