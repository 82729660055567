import { useDispatch, useSelector } from 'react-redux';
import CompaniesRow from '../atoms/CompaniesRow';
import FormCompanyModal from '../atoms/FormCompanyModal';
import { useEffect, useState } from 'react';
import { fetchCompanies } from '../../store/actions/actionCreator';
export default function TableJobs() {
    const dispatch = useDispatch();
    const companies = useSelector((state) => state.company.value);
    const [formType, setFormType] = useState(null);
    const [selected, setSelected] = useState(null);
    useEffect(() => {
        dispatch(fetchCompanies());
    }, []);
    return (
        <div className='overflow-x-auto pb-[5%] h-screen w-full px-10 py-5'>
            <div className='text-3xl my-2 text-center font-bold'>Companies List</div>
            <FormCompanyModal formType={formType} selected={selected} />
            <label
                onClick={() => {
                    setFormType('Tambah');
                    setSelected(null);
                }}
                htmlFor='company-modal'
                className='py-2 my-2 flex text-[13px] justify-center bg-blue-600 hover:bg-blue-500 text-white w-[170px] font-semibold cursor-pointer text-center rounded-lg'>
                Tambah Perusahaan
            </label>
            <table className='table-compact w-full mx-auto border-2 border-gray-600'>
                <thead className='bg-gray-700 text-white'>
                    <tr className='border-2 border-gray-600'>
                        <th>#</th>
                        <th>Logo</th>
                        <th>Nama</th>
                        <th>Deskripsi</th>
                        <th>Lokasi</th>
                        <th>Email</th>
                        <th colSpan='2'>Aksi</th>
                    </tr>
                </thead>
                <tbody className='bg-white'>
                    {companies.map((company, i) => {
                        return <CompaniesRow key={company.id} value={company} index={i} setFormType={setFormType} setSelected={setSelected} />;
                    })}
                </tbody>
            </table>
        </div>
    );
}
