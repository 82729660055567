import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSkill } from '../../store/actions/actionCreator';
export default function AddSkillModal(props) {
    const { selected } = props;
    const dispatch = useDispatch();
    const [errMsg, setErrMsg] = useState(null);
    const [form, setForm] = useState({
        JobId: '',
        companyName: '',
        name: '',
        level: '',
    });
    const inputHandler = (e) => {
        const { value, name } = e.target;
        const newForm = {
            ...form,
        };
        newForm[name] = value;
        setForm(newForm);
    };

    useEffect(() => {
        if (selected) {
            setForm({
                ...form,
                JobId: selected.id,
                companyName: selected.Company.name,
            });
        }
        setErrMsg(null);
    }, [selected]);

    const submitHandler = async (e) => {
        e.preventDefault();
        if (form.name === '') return setErrMsg('Nama tidak boleh kosong');
        if (form.level === '') return setErrMsg('Level tidak boleh kosong');
        dispatch(
            addSkill({
                JobId: form.JobId,
                name: form.name,
                level: form.level,
            })
        );
        document.getElementById('skill-modal').checked = false;
    };

    return (
        <>
            <input type='checkbox' id='skill-modal' className='modal-toggle' />
            <div className='modal'>
                <div className='modal-box relative'>
                    <label htmlFor='skill-modal' className='btn btn-sm btn-circle absolute right-2 top-2'>
                        ✕
                    </label>
                    <div className='px-6 py-6 lg:px-8'>
                        <h3 className='mb-4 text-xl font-medium text-gray-900 text-center'>Tambah skill untuk {form.companyName}</h3>
                        <form className='space-y-6' onSubmit={submitHandler}>
                            {errMsg && (
                                <div className='flex w-full text-red-700 justify-center bg-red-100 py-1 rounded-full'>
                                    <span>{errMsg}</span>
                                </div>
                            )}
                            <div>
                                <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900'>
                                    Nama Skill
                                </label>
                                <input
                                    value={form.name}
                                    name='name'
                                    onChange={inputHandler}
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                />
                            </div>
                            <div>
                                <label htmlFor='password' className='block mb-2 text-sm font-medium text-gray-900'>
                                    Level
                                </label>
                                <select
                                    value={form.level}
                                    name='level'
                                    onChange={inputHandler}
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '>
                                    <option value='' selected disabled>
                                        Pilih Jenis Loker
                                    </option>
                                    <option value='beginner'>beginner</option>
                                    <option value='intermediate'>intermediate</option>
                                    <option value='advanced'>advanced</option>
                                </select>
                            </div>

                            <button
                                type='submit'
                                className='w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'>
                                Tambah
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
