import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompanies, addJob, editJob } from '../../store/actions/actionCreator';
import Swal from 'sweetalert2';
export default function FormJobModal(props) {
    const { selected, formType } = props;
    const companies = useSelector((state) => state.company.value);
    const dispatch = useDispatch();
    const emptyForm = {
        title: '',
        description: '',
        CompanyId: '',
        jobType: '',
        skills: [{ name: '', level: '' }],
    };
    const [form, setForm] = useState(emptyForm);
    const [errMsg, setErrMsg] = useState(null);
    useEffect(() => {
        dispatch(fetchCompanies());
        setErrMsg(null);
        if (selected) {
            setForm({
                ...form,
                title: selected.title,
                description: selected.description,
                CompanyId: selected.Company.id,
                jobType: selected.jobType,
                id: selected.id,
                skills: selected.Skills,
            });
        } else {
            setForm(emptyForm);
        }
    }, [selected]);

    useEffect(() => {
        const { name, level } = form.skills[form.skills.length - 1];
        const array = [...form.skills, { name: '', level: '' }];
        if (name && level) {
            setForm({
                ...form,
                skills: array,
            });
        }
    }, [form.skills]);

    const inputHandler = (e) => {
        const { value, name } = e.target;
        const newForm = {
            ...form,
        };
        newForm[name] = value;
        setForm(newForm);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        if (form.title == '') return setErrMsg('Judul Loker tidak boleh kosong');
        if (form.description == '') return setErrMsg('deskripsi tidak boleh kosong');
        if (form.CompanyId == '') return setErrMsg('perusahaan tidak boleh kosong');
        if (form.jobType == '') return setErrMsg('type tidak boleh kosong');
        switch (formType) {
            case 'tambah':
                dispatch(addJob(form))
                    .then((_) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'success',
                            text: `berhasil menambahkan ${form.title}`,
                        });
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.message,
                        });
                    });
                break;
            case 'edit':
                dispatch(editJob(form))
                    .then((_) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'success',
                            text: `berhasil mengedit ${form.title}`,
                        });
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.message,
                        });
                    });
                break;
        }
        setForm(emptyForm);
        document.getElementById('jobs-modal').checked = false;
    };

    return (
        <>
            <input type='checkbox' id='jobs-modal' className='modal-toggle' />
            <div className='modal'>
                <div className='modal-box relative'>
                    <label htmlFor='jobs-modal' className='btn btn-sm btn-circle absolute right-2 top-2'>
                        ✕
                    </label>
                    <div className='px-6 py-6 lg:px-8'>
                        <h3 className='mb-4 text-xl font-medium text-gray-900 text-center'>{formType} pekerjaan</h3>
                        <form className='space-y-6' onSubmit={submitHandler}>
                            {errMsg && (
                                <div className='flex w-full text-red-700 justify-center bg-red-100 py-1 rounded-full'>
                                    <span>{errMsg}</span>
                                </div>
                            )}
                            <div>
                                <label htmlFor='title' className='block mb-2 text-sm font-medium text-gray-900'>
                                    Judul Loker
                                </label>
                                <input
                                    value={form.title}
                                    name='title'
                                    onChange={inputHandler}
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                />
                            </div>
                            <div className='grid md:grid-cols-2 md:gap-6'>
                                <div>
                                    <label htmlFor='jobType' className='block mb-2 text-sm font-medium text-gray-900'>
                                        Jenis Loker
                                    </label>
                                    <select
                                        value={form.jobType}
                                        name='jobType'
                                        onChange={inputHandler}
                                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'>
                                        <option value='' selected disabled>
                                            Pilih Jenis Loker
                                        </option>
                                        <option value='Magang'>Magang</option>
                                        <option value='Kontrak'>Kontrak</option>
                                        <option value='Paruh Waktu'>Paruh Waktu</option>
                                        <option value='Permanen'>Permanen</option>
                                    </select>
                                </div>

                                <div>
                                    <label htmlFor='CompanyId' className='block mb-2 text-sm font-medium text-gray-900'>
                                        Perusahaan
                                    </label>
                                    <select
                                        value={form.CompanyId}
                                        name='CompanyId'
                                        onChange={inputHandler}
                                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '>
                                        <option value='' selected disabled>
                                            Pilih Perusahaan
                                        </option>
                                        {companies.map((company) => {
                                            return (
                                                <option value={company.id} key={company.id}>
                                                    {company.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label htmlFor='description' className='block mb-2 text-sm font-medium text-gray-900'>
                                    Deskripsi
                                </label>
                                <textarea
                                    rows='7'
                                    name='description'
                                    onChange={inputHandler}
                                    value={form.description}
                                    className='block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500'></textarea>
                            </div>
                            {formType === 'tambah' && (
                                <div>
                                    <div className='divider'>Tambah skill</div>
                                    {form.skills.map((skill, index) => {
                                        return (
                                            <div className='grid md:grid-cols-3 md:gap-2'>
                                                <div className=''>
                                                    <input
                                                        placeholder='nama skill'
                                                        value={skill.name}
                                                        onChange={(e) => {
                                                            setForm((form) => {
                                                                const newSkill = {
                                                                    name: e.target.value,
                                                                    level: form.skills[index].level,
                                                                };
                                                                const newSkills = [...new Set(form.skills)];

                                                                newSkills[index] = newSkill;
                                                                return {
                                                                    ...form,
                                                                    skills: newSkills,
                                                                };
                                                            });
                                                        }}
                                                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                                    />
                                                </div>

                                                <div>
                                                    <select
                                                        value={skill.level}
                                                        onChange={(e) => {
                                                            setForm((form) => {
                                                                const newSkill = {
                                                                    level: e.target.value,
                                                                    name: form.skills[index].name,
                                                                };
                                                                const newSkills = [...new Set(form.skills)];
                                                                newSkills[index] = newSkill;
                                                                return {
                                                                    ...form,
                                                                    skills: newSkills,
                                                                };
                                                            });
                                                        }}
                                                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '>
                                                        <option value='' selected disabled>
                                                            Pilih Level
                                                        </option>
                                                        <option value='beginner'>pemula</option>
                                                        <option value='intermediate'>mengerti</option>
                                                        <option value='advanced'>mahir</option>
                                                    </select>
                                                </div>
                                                {index > 0 && (
                                                    <label
                                                        onClick={() => {
                                                            const newForm = {
                                                                ...form,
                                                            };
                                                            newForm.skills.splice(index, 1);
                                                            setForm(newForm);
                                                        }}
                                                        className='flex justify-center items-center bg-red-200 text-red-700 hover:bg-red-300 cursor-pointer rounded-md'>
                                                        Hapus
                                                    </label>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            <button
                                type='submit'
                                className='w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'>
                                {formType}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
