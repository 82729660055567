import { IS_LOADING, USER_FETCH_SUCCESS } from '../actions/actionType';

const initialState = {
    userLogin: '',
    isLogin: false,
    value: [],
    isLoading: false,
};

function userReducer(state = initialState, action) {
    switch (action.type) {
        case USER_FETCH_SUCCESS:
            return {
                ...state,
                value: action.payload,
            };
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
}

export default userReducer;
