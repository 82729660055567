import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormUserModal from '../atoms/FormAddUser';
import { fetchUser } from '../../store/actions/actionCreator';
import ProfileCard from '../atoms/ProfileCard';

export default function User() {
    const users = useSelector((state) => state.user.value);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUser());
    }, []);
    return (
        <div className='overflow-x-auto h-screen w-full flex flex-col px-10 py-5'>
            <div className='text-3xl my-2 text-center font-bold'>User List</div>
            <FormUserModal />
            <label
                htmlFor='user-modal'
                className='py-2 my-2 bg-blue-600 hover:bg-blue-500 text-white w-[150px] font-semibold cursor-pointer text-center rounded-lg'>
                Tambah User
            </label>
            <div className='flex gap-5 mt-5'>
                {users.map((user) => {
                    return <ProfileCard key={user.id} value={user} />;
                })}
            </div>
        </div>
    );
}
