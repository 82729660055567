import { useState } from 'react';
import { useRef } from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchUser, postRegister } from '../../store/actions/actionCreator';

export default function FormUserModal(props) {
    const modalRef = useRef();
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        username: '',
        email: '',
        password: '',
        phone: '',
        address: '',
    });
    const [error, setError] = useState(null);
    const inputHandler = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };
    const submitHandler = (e) => {
        e.preventDefault();
        if (form.username === '') {
            setError('username is Required');
        } else if (form.email === '') {
            setError('email is Required');
        } else if (form.password === '') {
            setError('password is Required');
        } else if (form.phone === '') {
            setError('phone is Required');
        } else if (form.address === '') {
            setError('address is Required');
        } else {
            dispatch(postRegister(form))
                .then((_) => {
                    Swal.fire({
                        icon: 'Success',
                        title: 'success',
                        text: 'Berhasil menambah user Baru',
                    });
                    dispatch(fetchUser());
                    document.getElementById('user-modal').checked = false;
                })
                .catch((err) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.message,
                    });
                });
        }
    };

    return (
        <>
            <input type='checkbox' id='user-modal' ref={modalRef} className='modal-toggle' />
            <div className='modal'>
                <div className='w-[50%] p-5 m-2 bg-white rounded-xl shadow-2xl shadow-gray-500/40 border-2'>
                    <h1 className='text-md text-right font-semibold text-blue-600 uppercase'>⬆ Registrasi</h1>
                    <form className='p-3' onSubmit={submitHandler}>
                        {error && (
                            <div className='flex w-full text-red-700 justify-center bg-red-100 py-1 rounded-full'>
                                <span>{error}</span>
                            </div>
                        )}
                        <div className='mb-4'>
                            <label htmlFor='email' className='block text-sm text-gray-800'>
                                Username
                            </label>
                            <input
                                type='text'
                                name='username'
                                value={form.username}
                                onChange={inputHandler}
                                className='block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            />
                        </div>
                        <div className='mb-4'>
                            <label htmlFor='email' className='block text-sm text-gray-800'>
                                Email
                            </label>
                            <input
                                type='email'
                                name='email'
                                value={form.email}
                                onChange={inputHandler}
                                className='block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            />
                        </div>
                        <div className='mb-4'>
                            <label htmlFor='email' className='block text-sm text-gray-800'>
                                Password
                            </label>
                            <input
                                type='password'
                                name='password'
                                value={form.password}
                                onChange={inputHandler}
                                className='block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            />
                        </div>
                        <div className='mb-4'>
                            <label htmlFor='email' className='block text-sm text-gray-800'>
                                Phone
                            </label>
                            <input
                                type='text'
                                name='phone'
                                value={form.phone}
                                onChange={inputHandler}
                                className='block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            />
                        </div>
                        <div className='mb-4'>
                            <label htmlFor='email' className='block text-sm text-gray-800'>
                                Address
                            </label>
                            <input
                                type='text'
                                name='address'
                                value={form.address}
                                onChange={inputHandler}
                                className='block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            />
                        </div>

                        <div className='flex items-center mt-5'>
                            <button className='w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600'>
                                Tambah Akun
                            </button>
                        </div>
                        <div className='flex items-center mt-5'>
                            <label
                                htmlFor='user-modal'
                                className='w-full px-4 text-center py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:bg-gray-600'>
                                Batal
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
