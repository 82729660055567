import { COMPANIES_FETCH_SUCCESS } from '../actions/actionType';

const initialState = {
    value: [],
};

function companyReducer(state = initialState, action) {
    switch (action.type) {
        case COMPANIES_FETCH_SUCCESS:
            return {
                ...state,
                value: action.payload,
            };

        default:
            return state;
    }
}

export default companyReducer;
