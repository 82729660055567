export default function ProfileCard(props) {
    const { id, username, email, phone, role, address } = props.value;
    return (
        <div className='max-w-xs w-[250px]'>
            <div className='bg-white shadow-xl rounded-lg py-3'>
                <div className='photo-wrapper p-2'>
                    <img
                        className='w-32 h-32 rounded-full border-4 border-gray-600/40 mx-auto'
                        src='https://www.seekpng.com/png/full/847-8474751_download-empty-profile.png'
                        alt='Profile'
                    />
                </div>
                <div className='p-2'>
                    <h3 className='text-center text-xl text-gray-900 font-medium leading-8'>{username}</h3>
                    <div className='text-center text-gray-400 text-xs font-semibold'>
                        <p>{role}</p>
                    </div>
                    <table className='text-xs my-3'>
                        <tbody>
                            <tr>
                                <td className='px-2 py-2 text-gray-500 font-semibold'>Address</td>
                                <td className='px-2 py-2 capitalize'>: {address}</td>
                            </tr>
                            <tr>
                                <td className='px-2 py-2 text-gray-500 font-semibold'>Phone</td>
                                <td className='px-2 py-2'>: {phone}</td>
                            </tr>
                            <tr>
                                <td className='px-2 py-2 text-gray-500 font-semibold'>Email</td>
                                <td className='px-2 py-2 whitespace-pre-wrap'>: {email}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
