import { Outlet } from 'react-router-dom';

import Sidebar from '../molecules/Sidebar';

export default function Content() {
    return (
        <div className='flex pt-[4em] fixed w-full'>
            <Sidebar />
            <div className='w-full'>
                <Outlet />
            </div>
        </div>
    );
}
