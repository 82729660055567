import router from './router/index';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

function App() {
    return (
        <div className='App font-[Poppins]'>
            <div className='w-full items-center min-h-screen pt-6 sm:justify-center sm:pt-0 bg-gray-100'>
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>
            </div>
        </div>
    );
}

export default App;
