import Button from '../atoms/Button';
import Logo from '../atoms/Logo';
function Navbar() {
    return (
        <div className='navbar bg-gradient-to-r from-white to-gray-900 gap-2 top-0 h-[2em] justify-between px-10 left-0 fixed z-50 border-b-4 p-3 border-gray-700 shadow-sm shadow-gray-700'>
            <Logo />
            <div className='translate-y-0 text-xl text-white'>Dashboard Admin CMS</div>
        </div>
    );
}

export default Navbar;
