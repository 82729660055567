import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
    const navigate = useNavigate();
    const activeLink = 'bg-gray-400 text-black font-bold rounded-xl';
    const normalLink = '';
    const logoutHandler = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Konfirmasi',
            text: 'Yakin kamu ingin logout?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Logout',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear();
                navigate('/login');
            }
        });
    };
    return (
        <div className='drawer-side'>
            <label htmlFor='my-drawer-2' className='drawer-overlay'></label>
            <ul className='menu p-4 w-70 bg-gray-700 text-white border-r-2 border-r-slate-300/40 h-screen '>
                <NavLink to='/' className={({ isActive }) => (isActive ? activeLink : normalLink)}>
                    <label className='text-center flex gap-2 rounded-xl text-md w-full px-6 py-3 hover:bg-gray-300 hover:text-black '>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-6 h-6'>
                            <path strokeLinecap='round' strokeLinejoin='round' d='M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5' />
                        </svg>
                        Dashbaord
                    </label>
                </NavLink>
                <NavLink to='/jobs' className={({ isActive }) => (isActive ? activeLink : normalLink)}>
                    <label className='text-center flex gap-2 rounded-xl text-md w-full px-6 py-3 hover:bg-gray-300 hover:text-black'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-6 h-6'>
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z'
                            />
                        </svg>
                        Loker
                    </label>
                </NavLink>
                <NavLink to='/companies' className={({ isActive }) => (isActive ? activeLink : normalLink)}>
                    <label className='text-center flex gap-2 rounded-xl text-md w-full px-6 py-3 hover:bg-gray-300 hover:text-black '>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-6 h-6'>
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z'
                            />
                        </svg>
                        Perusahaan
                    </label>
                </NavLink>
                <NavLink to='/users' className={({ isActive }) => (isActive ? activeLink : normalLink)}>
                    <label className='text-center flex gap-2 rounded-xl text-md w-full px-6 py-3 hover:bg-gray-300 hover:text-black '>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-6 h-6'>
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z'
                            />
                        </svg>
                        User
                    </label>
                </NavLink>
                <div className='divider before:bg-[white] after:bg-[white]  '>account</div>
                <div onClick={logoutHandler} className={({ isActive }) => (isActive ? activeLink : normalLink)}>
                    <label className='text-center flex gap-2 rounded-xl text-md w-full px-6 py-3 hover:bg-gray-300 hover:text-black '>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-6 h-6'>
                            <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                        </svg>
                        Keluar
                    </label>
                </div>
            </ul>
        </div>
    );
}

export default Sidebar;
