import Button from './Button';
import { useDispatch } from 'react-redux';
import { deleteCompany } from '../../store/actions/actionCreator';
import Swal from 'sweetalert2';
export default function JobsRow(props) {
    const { index, value, setFormType, setSelected } = props;
    const { id, companyLogo, description, email, location, name } = value;
    const dispatch = useDispatch();
    const handleButtonDelete = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Konfirmasi',
            text: `Hapus ${name}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCompany(id))
                    .then((_) => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: `Perusahaan ${name} berhasil dihapus`,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.message,
                        });
                    });
            }
        });
    };
    return (
        <tr className='hover:font-semibold w-full font-thin text-gray-800 hover:bg-blue-50 text-center border-2 border-gray-200'>
            <th>{index + 1}</th>
            <td className='py-2'>
                <img src={companyLogo} className='w-[200px] h-auto object-cover mx-auto' alt='companyLogo' />
            </td>
            <td className='py-5 text-xl'>{name}</td>
            <td className='py-5 px-4 text-[12px] w-[30%] h-auto whitespace-pre-wrap'>{description}</td>
            <td className='py-5'>{location}</td>
            <td className='py-5'>{email}</td>
            <td className='py-5 w-[2%]'>
                <label
                    onClick={() => {
                        setFormType('Edit');
                        setSelected(value);
                    }}
                    htmlFor='company-modal'
                    className='bg-blue-600 hover:bg-blue-500 text-white flex justify-center gap-x-2 items-center py-2 px-4 rounded-md cursor-pointer'>
                    Edit
                </label>
            </td>
            <td className=' py-5 px-5 w-[2%]'>
                <Button onClick={handleButtonDelete} className='bg-red-600 hover:bg-red-500 text-white' text='Delete' />
            </td>
        </tr>
    );
}
