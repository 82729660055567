import { combineReducers } from 'redux';
import companyReducer from './companyReducer';
import jobReducer from './jobReducer';
import skillReducer from './skillReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
    user: userReducer,
    job: jobReducer,
    company: companyReducer,
    skill: skillReducer,
});

export default rootReducer;
