const initialState = {
    Value: [],
};

function skillReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}

export default skillReducer;
