import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addCompany, editCompany } from '../../store/actions/actionCreator';
import Swal from 'sweetalert2';

export default function FormCompanyModal(props) {
    const modalRef = useRef();
    const { formType, selected } = props;
    const dispatch = useDispatch();
    const emptyForm = {
        name: '',
        companyLogo: '',
        location: '',
        email: '',
        description: '',
    };
    const [form, setForm] = useState(emptyForm);
    const [errMsg, setErrMsg] = useState(null);
    const inputHandler = (e) => {
        const { name, value } = e.target;
        const newForm = {
            ...form,
        };
        newForm[name] = value;
        setForm(newForm);
    };

    useEffect(() => {
        if (selected) {
            setForm(selected);
        } else {
            setForm(emptyForm);
        }
        setErrMsg(null);
    }, [selected]);

    const handleSubmitButton = (e) => {
        e.preventDefault();
        if (form.name === '') return setErrMsg('Nama tidak boleh kosong');
        if (form.companyLogo === '') return setErrMsg('Logo Perusahaan tidak boleh kosong');
        if (form.email === '') return setErrMsg('email tidak boleh kosong');
        if (form.description === '') return setErrMsg('deskripsi tidak boleh kosong');
        switch (formType) {
            case 'Tambah':
                dispatch(addCompany(form))
                    .then((_) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'success',
                            text: `berhasil menambahkan ${form.name}`,
                        });
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.message,
                        });
                    });
                break;

            case 'Edit':
                dispatch(editCompany(form))
                    .then((_) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'success',
                            text: `berhasil mengedit ${form.name}`,
                        });
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.message,
                        });
                    });
                break;
        }
        modalRef.current.checked = false;
    };

    return (
        <>
            <input type='checkbox' id='company-modal' ref={modalRef} className='modal-toggle' />
            <div className='modal'>
                <div className='modal-box relative'>
                    <label htmlFor='company-modal' className='btn btn-sm btn-circle absolute right-2 top-2'>
                        ✕
                    </label>
                    <div className='px-6 py-6 lg:px-8'>
                        <h3 className='mb-4 text-xl font-medium text-gray-900 text-center'>{formType} Perusahaan</h3>
                        <form className='space-y-6' onSubmit={handleSubmitButton}>
                            {errMsg && (
                                <div className='flex w-full text-red-700 justify-center bg-red-100 py-1 rounded-full'>
                                    <span>{errMsg}</span>
                                </div>
                            )}
                            <div>
                                <label htmlFor='name' className='block mb-2 text-sm font-medium text-gray-900'>
                                    Nama Perusahaan
                                </label>
                                <input
                                    value={form.name}
                                    name='name'
                                    onChange={inputHandler}
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                />
                            </div>
                            <div className='grid md:grid-cols-2 md:gap-6'>
                                <div>
                                    <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900'>
                                        Email
                                    </label>
                                    <input
                                        value={form.email}
                                        name='email'
                                        onChange={inputHandler}
                                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                    />
                                </div>
                                <div>
                                    <label htmlFor='location' className='block mb-2 text-sm font-medium text-gray-900'>
                                        Lokasi
                                    </label>
                                    <input
                                        value={form.location}
                                        name='location'
                                        onChange={inputHandler}
                                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor='companyLogo' className='block mb-2 text-sm font-medium text-gray-900'>
                                    Logo Perusahaan (Url)
                                </label>
                                <input
                                    value={form.companyLogo}
                                    name='companyLogo'
                                    onChange={inputHandler}
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                                />
                            </div>
                            <div>
                                <label htmlFor='description' className='block mb-2 text-sm font-medium text-gray-900'>
                                    Deskripsi
                                </label>
                                <textarea
                                    value={form.description}
                                    name='description'
                                    onChange={inputHandler}
                                    rows='6'
                                    className='block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500'></textarea>
                            </div>
                            <button
                                type='submit'
                                className='w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'>
                                {formType}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
