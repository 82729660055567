import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanies, fetchJobs, fetchUser } from '../../store/actions/actionCreator';

export default function Dashboard() {
    const dispatch = useDispatch();
    const jobs = useSelector((state) => state.job.value);
    const companies = useSelector((state) => state.company.value);
    const users = useSelector((state) => state.user.value);

    useEffect(() => {
        dispatch(fetchJobs());
        dispatch(fetchCompanies());
        dispatch(fetchUser());
    }, []);

    return (
        <div className='p-10 flex gap-5'>
            <div className='stats bg-blue-200 shadow-xl border-4 border-white'>
                <div className='stat'>
                    <div className='stat-title'>Total Jobs</div>
                    <div className='stat-value text-center my-5 text-[60px]'>{jobs.length}</div>
                </div>
            </div>
            <div className='stats bg-yellow-100 shadow-xl border-4 border-white'>
                <div className='stat'>
                    <div className='stat-title'>Total Companies</div>
                    <div className='stat-value text-center my-5 text-[60px]'>{companies.length}</div>
                </div>
            </div>
            <div className='stats bg-green-100 shadow-xl border-4 border-white'>
                <div className='stat'>
                    <div className='stat-title'>Total Users</div>
                    <div className='stat-value text-center my-5 text-[60px]'>{users.length}</div>
                </div>
            </div>
        </div>
    );
}
