import { COMPANIES_FETCH_SUCCESS, IS_LOADING, JOBS_FETCH_SUCCESS, USER_FETCH_SUCCESS } from './actionType';

//! STORE
const URL = 'https://carikerja-backend.mashayyik.dev';

export const jobsFetchSuccess = (payload) => {
    return {
        type: JOBS_FETCH_SUCCESS,
        payload: payload,
    };
};

export const companiesFetchSuccess = (payload) => {
    return {
        type: COMPANIES_FETCH_SUCCESS,
        payload: payload,
    };
};

export const userFetchSuccess = (payload) => {
    return {
        type: USER_FETCH_SUCCESS,
        payload: payload,
    };
};

export const isLoading = (payload) => {
    return {
        type: IS_LOADING,
        payload: payload,
    };
};

//! LOGIN/REGISTER
export const postLogin = (data) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/login`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            return json;
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};
export const postRegister = (data) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/register`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!response.ok)
                throw {
                    message: json.message,
                };
            const json = await response.json();
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};

//! READ
export const fetchJobs = () => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/jobs`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(jobsFetchSuccess(json));
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};

export const fetchCompanies = () => {
    return async (dispatch, getState) => {
        try {
            const response = await fetch(`${URL}/companies`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(companiesFetchSuccess(json));
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};
export const fetchUser = () => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/users`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    access_token: localStorage.access_token,
                },
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(userFetchSuccess(json));
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};

//! CREATE
export const addJob = (data) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/jobs`, {
                method: 'post',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    access_token: localStorage.access_token,
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(fetchJobs());
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};

export const addSkill = (data) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/skills`, {
                method: 'post',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    access_token: localStorage.access_token,
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(fetchJobs());
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};
export const addCompany = (data) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/companies`, {
                method: 'post',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    access_token: localStorage.access_token,
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(fetchCompanies());
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};

//! UPDATE
export const editJob = (data) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/jobs/${data.id}`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    access_token: localStorage.access_token,
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(fetchJobs());
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};

export const editCompany = (data) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/companies/${data.id}`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    access_token: localStorage.access_token,
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(fetchCompanies());
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};

export const editSkills = (data) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/skills/${data.id}`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    access_token: localStorage.access_token,
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(fetchJobs());
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};

//! DELETE
export const deleteJob = (id) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/jobs/${id}`, {
                method: 'delete',
                headers: {
                    'Content-Type': 'application/json',
                    access_token: localStorage.access_token,
                },
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(fetchJobs());
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};

export const deleteCompany = (id) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/companies/${id}`, {
                method: 'delete',
                headers: {
                    'Content-Type': 'application/json',
                    access_token: localStorage.access_token,
                },
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(fetchCompanies());
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};
export const deleteSkill = (id) => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));
        try {
            const response = await fetch(`${URL}/skills/${id}`, {
                method: 'delete',
                headers: {
                    'Content-Type': 'application/json',
                    access_token: localStorage.access_token,
                },
            });
            const json = await response.json();
            if (!response.ok)
                throw {
                    message: json.message,
                };
            dispatch(fetchJobs());
        } catch (err) {
            throw err;
        } finally {
            dispatch(isLoading(false));
        }
    };
};
